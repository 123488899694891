import React, { useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './WebPage.css'; // Import your CSS file for styling
import { Modal } from 'react-bootstrap';
//import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Body from '../Body';
import Headers from '../SpeakPage/Headers';
import Main from '../CallPage/Main';
import GoLiveMain from '../GoLive/GoLiveMain';
import FeedPage from "../FeedBack/FeedPage";
import FirstPage from "../FirstPage/FirstPage";
import Permission from "../Permission";
import { ModalContext } from '../../hooks/useModalState';
//import myImage from '../../images/NewGIF.gif';
import videoIcon from '../../images/video-solid.svg';
import welcomeLady from '../../images/saro-welcome-lady.mp4';
import welcomeLadyPoster from '../../images/saro-welcome-lady-poster.jpg';
import { hostingURL, saroAPI, retailerNAME } from '../constant'; // Assuming you have saroAPI and retailerNAME here
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios'; // Import Axios for API calls
import GoLive from '../GoLive';
import BroadcastFeedback from '../BroadcastFeedback';
import Decision from '../Decision';
import Videos from '../ShopableVideos/Videos'
import { useData } from '../../Context'; // Ensure this is correctly imported
import CarouselReels from '../CarouselReels/CarouselReels'


const WebPage = () => {
  const [products, setProducts] = useState([]);

  const [showModal, setShowModal] = useState(false); // Set initial state to false to hide the modal
  const [currentPage, setCurrentPage] = useState(null); // Start with no page
  const [callDuration, setCallDuration] = useState(0); // State to track call duration
  const [modalState, setModalState] = useState('dynamic');
  const [showPopup, setShowPopup] = useState(false); // State to control if the popup should be shown
  const [showShopableVideos, setShowShopableVideos] = useState(false); // State to control if the popup should be shown
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Popup visibility state
  const [isWebView, setIsWebView] = useState(window.innerWidth > 768); // Tracks if the view is greater than 768px
   // API call to authenticateToken on component load
   const [isOneToOneCallEnabled, setIsOneToOneCallEnabled] = useState(false);
   const [isGoLiveEnabled, setIsGoLiveEnabled] = useState(false);
   const { data } = useData(); // Add this line to get data from the context

   
//For Hover effect - GO Live & One-to-one
   useEffect(() => {
    const handleOutsideClick = (event) => {
      const popupElement = document.querySelector('.popup-container');
      if (popupElement && !popupElement.contains(event.target)) {
        setIsPopupVisible(false); // Close popup when clicking outside
      }
    };
  
    if (isPopupVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick); // Cleanup
    };
  }, [isPopupVisible]);
  
  


  // Function to handle navigation to different pages within the modal
  const handleNavigation = (page, extraData) => {
    setIsPopupVisible(false); // Close the popup
    // setShowPopup(false); // Hide the popup trigger
    setShowModal(true); // Show the modal for the new page
    setCurrentPage(page);
    if (page === "Videos" && extraData) {
      setProducts(extraData); // Save products in state
    }
  };

  useEffect(() => {
    if (showModal) {
      // Start updating the call duration every second when the modal is shown
      const interval = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);

      // Clear the interval when the modal is closed
      return () => clearInterval(interval);
    }
  }, [showModal]);

 
  // Updated authenticateToken function
  useEffect(() => {
    const authenticateToken = async () => {
      try {
        const response = await axios.post(`${saroAPI}/authenticateToken`, {
          retailerName: retailerNAME,
        });
  
        //console.log("Full API Response:", response);
  
        if (response && response.data && response.data.data) {
          const { isOneToOneCallEnabled, isGoLiveEnabled, isChatEnabled } = response.data.data.data;
  
          //console.log("isOneToOneCallEnabled:", isOneToOneCallEnabled);
          //console.log("isGoLiveEnabled:", isGoLiveEnabled);
  
          // Store flags in state
          setIsOneToOneCallEnabled(isOneToOneCallEnabled);
          setIsGoLiveEnabled(true);
  
          // Set currentPage based on flags
          if (isOneToOneCallEnabled && isGoLiveEnabled) {
            setCurrentPage("Decision");
            //console.log("Setting currentPage to Decision");
            setShowPopup(true);
            setShowShopableVideos(false);
          } else if (isOneToOneCallEnabled) {
            setCurrentPage("Body");
            //console.log("Setting currentPage to Body");
            setShowPopup(true);
            setShowShopableVideos(false);
          } else if (isGoLiveEnabled) {
            setCurrentPage("GoLive");
            //console.log("Setting currentPage to GoLive");
            setShowPopup(true);
            setShowShopableVideos(false);
          } 
          else if (isChatEnabled) {
            setCurrentPage("Videos");
            //console.log("Setting currentPage to Videos");
            setShowPopup(false);
            setShowShopableVideos(true);
          }
          
          else {
            setCurrentPage("Videos");
            //console.log("Setting currentPage to FirstPage");
          }

          // setShowPopup(true);
          // setShowShopableVideos(false);
          // setCurrentPage("GoLive");
  
           // Show popup if API returns success
        } else {
          //console.log("API response data missing or invalid.");
          setShowPopup(false);
        }
      } catch (error) {
        console.error("Error authenticating token:", error);
        setShowPopup(false); // Hide popup in case of error
      }
    };
  
    authenticateToken();
  }, []);
  
  // Updated toggleModal function
  const toggleModal = () => {
    setShowModal(!showModal);
  
    if (!showModal) {
      if (isOneToOneCallEnabled && isGoLiveEnabled) {
        setCurrentPage("Decision");
      } else if (isGoLiveEnabled) {
        setCurrentPage("GoLive");
      } else if (isOneToOneCallEnabled) {
        setCurrentPage("Body");
      } else {
        setCurrentPage(null);
      }
    }
  };
  

  return (
    <div className="container1"
    style={{
      maxWidth: '100vw',
    }}
    >
      

      {showPopup && (
        <div
          className="popup-trigger"
          onClick={() => {
            if ((isOneToOneCallEnabled || isGoLiveEnabled) && !(isOneToOneCallEnabled && isGoLiveEnabled)) {
              toggleModal(); // Allow toggle only if one is enabled
            } else {
              toggleModal(); // Allow toggle only if one is enabled
            }
          }}          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1000,
          }}
          onMouseEnter={() => {
            if (window.innerWidth > 768) { // Ensure viewport width is greater than 768
              setIsPopupVisible(true);
            }
          }}

        >
          {/* Image container */}
          <div
            style={{
              position: 'relative',
              width: '120px', // Adjust size
              aspectRatio: '2000 / 1727', // To keep the aspect ratio as requested
              borderRadius: '12px', // Rounded corners
              overflow: 'hidden',
            }}
          >
            <video
              src={hostingURL + welcomeLady}
              poster={hostingURL + welcomeLadyPoster}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              autoPlay
              muted
              loop
              playsInline
            ></video>
            {/* Video icon */}
            <div
              style={{
                position: 'absolute',
                top: '5px',
                right: '10px',
                width: '15px',
                height: '15px',
                
              }}
              
            >
              <img src={hostingURL + videoIcon} alt="Video Icon" width="15px" height="15px" />
            </div>
            {/* Bottom text */}
            <div
              style={{
                position: 'absolute',
                bottom: '0px',
                width: '100%',
                textAlign: 'center',
                color: 'white',
                fontWeight: 'regular',
                fontSize: '12px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional dark background for readability
              }}
            >
              Make a call for live product demo
            </div>
          </div>
        </div>
      )}

  {showShopableVideos && (
    <div
    className="shopable-videos-container"
  >
    {/* <Videos
      products={products} // Pass the fetched products
      onPageChange={handleNavigation}
      setShowModal={setShowModal}
      callDuration={callDuration}
    />
  </div> */}

 <CarouselReels
      products={products} // Pass the fetched products
      onPageChange={handleNavigation}
      setShowModal={setShowModal}
      callDuration={callDuration}
    />
  </div> 

  )}

{isPopupVisible && isWebView && isOneToOneCallEnabled && isGoLiveEnabled && (  
  <div
  className="popup-container"
  style={{
    position: 'fixed',
    bottom: '120px', // Position above the video icon
    right: '200px',
    width: '300px', // Adjust width as needed
    backgroundColor: 'white',
    borderRadius: '10px',
    zIndex: 2000,
    padding: '20px',
    pointerEvents: 'auto', // Allow interactions with the content inside the popup

  }}
>
<Decision onPageChange={handleNavigation}setShowModal={setShowModal} callDuration={callDuration}/></div>

)}




      <ModalContext.Provider value={{ modalState, setModalState }}>
        <Modal show={showModal} onHide={toggleModal} centered backdrop={modalState}>
          <Modal.Body className="modal2">
            {currentPage === "Body" && <Body onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
            {currentPage === "GoLive" && <GoLive onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
            {currentPage === "GoLiveMain" && <GoLiveMain onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
            {currentPage === "Main" && <Main onPageChange={handleNavigation} callDuration={callDuration} />}
            {currentPage === "Headers" && <Headers onPageChange={handleNavigation} callDuration={callDuration} setShowModal={setShowModal} />}
            {currentPage === "FirstPage" && <FirstPage onPageChange={handleNavigation} callDuration={callDuration} />}
            {currentPage === "FeedPage" && <FeedPage onPageChange={handleNavigation} callDuration={callDuration} />}
            {currentPage === "Permission" && <Permission onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
            {currentPage === "BroadcastFeedback" && <BroadcastFeedback onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
            {currentPage === "Decision" && <Decision onPageChange={handleNavigation}setShowModal={setShowModal} callDuration={callDuration}/>}
            {/* {currentPage === "Videos" && <Videos onPageChange={handleNavigation}setShowModal={setShowModal} callDuration={callDuration}/>} */}
           {currentPage === "Videos" && (
  <Videos 
    products={products} // Pass the fetched products
    onPageChange={handleNavigation}
    setShowModal={setShowModal}
    callDuration={callDuration}


      />
    )}



          </Modal.Body>
          <Modal.Body className="modal3">
          
            {/* {currentPage === "Decision" && <Decision onPageChange={handleNavigation}setShowModal={setShowModal} callDuration={callDuration}/>} */}

          </Modal.Body>
         


  
   
        </Modal>
      </ModalContext.Provider>
    </div>
  );
};

export default WebPage;


// import React, { useState, useEffect } from 'react';
// import './WebPage.css'; // Import your CSS file for styling
// import { Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
// import Body from '../Body';
// import Headers from '../SpeakPage/Headers';
// import Main from '../CallPage/Main';
// import GoLiveMain from '../GoLive/GoLiveMain';
// import FeedPage from "../FeedBack/FeedPage";
// import FirstPage from "../FirstPage/FirstPage";
// import Permission from "../Permission";
// import { ModalContext } from '../../hooks/useModalState';
// //import myImage from '../../images/NewGIF.gif';
// import videoIcon from '../../images/video-solid.svg';
// import welcomeLady from '../../images/saro-welcome-lady.mp4';
// import welcomeLadyPoster from '../../images/saro-welcome-lady-poster.jpg';
// import { hostingURL, saroAPI, retailerNAME } from '../constant'; // Assuming you have saroAPI and retailerNAME here
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import axios from 'axios'; // Import Axios for API calls
// import GoLive from '../GoLive';
// import BroadcastFeedback from '../BroadcastFeedback';
// import Decision from '../Decision';

// const WebPage = () => {
//   const [showModal, setShowModal] = useState(false); // Set initial state to false to hide the modal
//   const [currentPage, setCurrentPage] = useState(null); // Start with no page
//   const [callDuration, setCallDuration] = useState(0); // State to track call duration
//   const [modalState, setModalState] = useState('dynamic');
//   const [showPopup, setShowPopup] = useState(false); // State to control if the popup should be shown
//   const [isPopupVisible, setIsPopupVisible] = useState(false); // Popup visibility state
//   const [isWebView, setIsWebView] = useState(window.innerWidth > 768); // Tracks if the view is greater than 768px
//    // API call to authenticateToken on component load
//    const [isOneToOneCallEnabled, setIsOneToOneCallEnabled] = useState(false);
//    const [isGoLiveEnabled, setIsGoLiveEnabled] = useState(false);
   

//    useEffect(() => {
//     const handleOutsideClick = (event) => {
//       const popupElement = document.querySelector('.popup-container');
//       if (popupElement && !popupElement.contains(event.target)) {
//         setIsPopupVisible(false); // Close popup when clicking outside
//       }
//     };
  
//     if (isPopupVisible) {
//       document.addEventListener('mousedown', handleOutsideClick);
//     } else {
//       document.removeEventListener('mousedown', handleOutsideClick);
//     }
  
//     return () => {
//       document.removeEventListener('mousedown', handleOutsideClick); // Cleanup
//     };
//   }, [isPopupVisible]);
  
  


//   // Function to handle navigation to different pages within the modal
//   const handleNavigation = (page) => {
//     setIsPopupVisible(false); // Close the popup
//     // setShowPopup(false); // Hide the popup trigger
//     setShowModal(true); // Show the modal for the new page
//     setCurrentPage(page);
//   };

//   useEffect(() => {
//     if (showModal) {
//       // Start updating the call duration every second when the modal is shown
//       const interval = setInterval(() => {
//         setCallDuration((prevDuration) => prevDuration + 1);
//       }, 1000);

//       // Clear the interval when the modal is closed
//       return () => clearInterval(interval);
//     }
//   }, [showModal]);

 
//   // Updated authenticateToken function
//   useEffect(() => {
//     const authenticateToken = async () => {
//       try {
//         const response = await axios.post(`${saroAPI}/authenticateToken`, {
//           retailerName: retailerNAME,
//         });
  
//         console.log("Full API Response:", response);
  
//         if (response && response.data && response.data.data) {
//           const { isOneToOneCallEnabled, isGoLiveEnabled } = response.data.data.data;
  
//           console.log("isOneToOneCallEnabled:", isOneToOneCallEnabled);
//           console.log("isGoLiveEnabled:", isGoLiveEnabled);
  
//           // Store flags in state
//           setIsOneToOneCallEnabled(isOneToOneCallEnabled);
//           setIsGoLiveEnabled(isGoLiveEnabled);
  
//           // Set currentPage based on flags
//           if (isOneToOneCallEnabled && isGoLiveEnabled) {
//             setCurrentPage("Decision");
//             console.log("Setting currentPage to Decision");
//           } else if (isOneToOneCallEnabled) {
//             setCurrentPage("Body");
//             console.log("Setting currentPage to Body");
//           } else if (isGoLiveEnabled) {
//             setCurrentPage("GoLive");
//             console.log("Setting currentPage to GoLive");
//           } else {
//             setCurrentPage("FirstPage");
//             console.log("Setting currentPage to FirstPage");
//           }
  
//           setShowPopup(true); // Show popup if API returns success
//         } else {
//           console.log("API response data missing or invalid.");
//           setShowPopup(false);
//         }
//       } catch (error) {
//         console.error("Error authenticating token:", error);
//         setShowPopup(false); // Hide popup in case of error
//       }
//     };
  
//     authenticateToken();
//   }, []);
  
//   // Updated toggleModal function
//   const toggleModal = () => {
//     setShowModal(!showModal);
  
//     if (!showModal) {
//       if (isOneToOneCallEnabled && isGoLiveEnabled) {
//         setCurrentPage("Decision");
//       } else if (isGoLiveEnabled) {
//         setCurrentPage("GoLive");
//       } else if (isOneToOneCallEnabled) {
//         setCurrentPage("Body");
//       } else {
//         setCurrentPage(null);
//       }
//     }
//   };
  

//   return (
//     <div className="container">
//       {showPopup && (
//         <div
//           className="popup-trigger"
//           onClick={() => {
//             if ((isOneToOneCallEnabled || isGoLiveEnabled) && !(isOneToOneCallEnabled && isGoLiveEnabled)) {
//               toggleModal(); // Allow toggle only if one is enabled
//             }
//           }}          style={{
//             position: 'fixed',
//             bottom: '20px',
//             right: '20px',
//             zIndex: 1000,
//           }}
//           onMouseEnter={() => {
//             if (window.innerWidth > 768) { // Ensure viewport width is greater than 768
//               setIsPopupVisible(true);
//             }
//           }}

//         >
//           <div
//             style={{
//               position: 'relative',
//               width: '120px', // Adjust size
//               aspectRatio: '2000 / 1727', // To keep the aspect ratio as requested
//               borderRadius: '12px', // Rounded corners
//               overflow: 'hidden',
//             }}
//           >
//             <video
//               src={hostingURL + welcomeLady}
//               poster={hostingURL + welcomeLadyPoster}
//               style={{
//                 width: '100%',
//                 height: '100%',
//                 objectFit: 'cover',
//               }}
//               autoPlay
//               muted
//               loop
//               playsInline
//             ></video>
//             {/* Video icon */}
//             <div
//               style={{
//                 position: 'absolute',
//                 top: '5px',
//                 right: '10px',
//                 width: '15px',
//                 height: '15px',
                
//               }}
              
//             >
//               <img src={hostingURL + videoIcon} alt="Video Icon" width="15px" height="15px" />
//             </div>
//             <div
//               style={{
//                 position: 'absolute',
//                 bottom: '0px',
//                 width: '100%',
//                 textAlign: 'center',
//                 color: 'white',
//                 fontWeight: 'regular',
//                 fontSize: '12px',
//                 backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional dark background for readability
//               }}
//             >
//               Make a call for live product demo
//             </div>
//           </div>
//         </div>
//       )}
// {isPopupVisible && isWebView && isOneToOneCallEnabled && isGoLiveEnabled && (  
//   <div
//   className="popup-container"
//   style={{
//     position: 'fixed',
//     bottom: '120px', // Position above the video icon
//     right: '200px',
//     width: '300px', // Adjust width as needed
//     backgroundColor: 'white',
//     borderRadius: '10px',
//     zIndex: 2000,
//     padding: '20px',
//     pointerEvents: 'auto', // Allow interactions with the content inside the popup

//   }}
// >
// <Decision onPageChange={handleNavigation}setShowModal={setShowModal} callDuration={callDuration}/></div>

// )}




//       <ModalContext.Provider value={{ modalState, setModalState }}>
//         <Modal show={showModal} onHide={toggleModal} centered backdrop={modalState}>
//           <Modal.Body className="modal2">
//             {currentPage === "Body" && <Body onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
//             {currentPage === "GoLive" && <GoLive onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
//             {currentPage === "GoLiveMain" && <GoLiveMain onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
//             {currentPage === "Main" && <Main onPageChange={handleNavigation} callDuration={callDuration} />}
//             {currentPage === "Headers" && <Headers onPageChange={handleNavigation} callDuration={callDuration} setShowModal={setShowModal} />}
//             {currentPage === "FirstPage" && <FirstPage onPageChange={handleNavigation} callDuration={callDuration} />}
//             {currentPage === "FeedPage" && <FeedPage onPageChange={handleNavigation} callDuration={callDuration} />}
//             {currentPage === "Permission" && <Permission onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
//             {currentPage === "BroadcastFeedback" && <BroadcastFeedback onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
//             {currentPage === "Decision" && <Decision onPageChange={handleNavigation}setShowModal={setShowModal} callDuration={callDuration}/>}
            

//           </Modal.Body>
//           <Modal.Body className="modal3">
          

//           </Modal.Body>
         


  
   
//         </Modal>
//       </ModalContext.Provider>
//     </div>
//   );
// };

// export default WebPage;
