import { saroAPI, retailerNAME } from '../constant'; // No need for image imports now
import axios from 'axios';
// Import OpenTok client library
const OT = require('@opentok/client');

// Declare session variable at a higher scope
let session;
let publisher;
let customerCallUUID;
let customerRetailerName;
let isMicrophoneEnabled = true; // Global variable for microphone state
let isVideoEnabled = true; // Global variable for video state
let showInitialImage, showAvailableImage;
let isSignalListenerAdded = false; // To prevent multiple signal listeners


// Declare timer variables
let timerNextClerk;
let timer;
let nextClerkTime = 20000;
let callTimeOutTimer = 60000; // Increase the call timeout if needed

let handleVideoToggleRef = null;

// Define setCallStartTime function
function setCallStartTime() {
  // Implementation of setCallStartTime
  // For example, you can log the call start time
  //console.log('Call start time set');
}

function getURLParameter(name) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}

// const retailerName = getURLParameter('retailerName');

// Set image callbacks for showing images
export function setImageCallbacks(initialImageCallback, availableImageCallback) {
  showInitialImage = initialImageCallback;
  showAvailableImage = availableImageCallback;
}


export async function startBroadcast(
  liveShowId,
  setIsStreamCreated,
  setIsStreamDestroyed,
  setUserCount,
  setSelectedProductId,
  setUserLikeCount
) {
  const apiKey = "9221d0f6-71bd-48a0-976d-5355c89962cf"; // Replace with your API key
  // const liveShowId = "qNEGWDZJag2mhTFdPfvf";
  const retailerName = retailerNAME; // Replace with your retailer name
  const role = "audience";

  let sessionId, token; 

  try {
    const response = await axios.post(
      `${saroAPI}/joinBroadcast`,
      { liveShowId, retailerName, role },
      { headers: { "Content-Type": "application/json" } }
    );

    if (response.status === 200) {
      const data = response.data;
      sessionId = data.sessionId;
      token = data.token;
    } else {
      console.error("Failed to fetch session details. Status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error while fetching session details:", error.message);
    return null;
  }

  if (!sessionId || !token) {
    console.error("Missing sessionId or token");
    return null;
  }

  let session;
  try {
    session = OT.initSession(apiKey, sessionId);
    //console.log("OpenTok session initialized successfully.");
  } catch (error) {
    //console.error("Error initializing session:", error.message);
    return null;
  }

// Start user count at 0
setUserCount(-1);

// Stream Created Listener
session.on("streamCreated", (event) => {
  //console.log("Stream Created:", event.stream);
  setIsStreamCreated(true);
  setIsStreamDestroyed(false);

  session.subscribe(
    event.stream,
    "subscriber",
    {
      insertMode: "append",
      width: "100%",
      height: "100%",
      style: { buttonDisplayMode: "off" },
    },
    (error) => {
      if (error) {
        console.error("Error subscribing to the stream:", error.message);
      }
    }
  );
});

  // Stream Destroyed Listener
session.on("streamDestroyed", (event) => {
    //console.log("Stream Destroyed:", event.reason);
    setIsStreamCreated(false);
    setIsStreamDestroyed(true);
  });

  // Connection Listeners
  const activeConnections = new Set();

  session.on("connectionCreated", (event) => {
    const { connectionId } = event.connection;
    if (!activeConnections.has(connectionId)) {
      activeConnections.add(connectionId);
      //console.log("A user joined the session.");
      setUserCount((prevCount) => prevCount + 1); // Increment user count
    }
  });
  
  session.on("connectionDestroyed", (event) => {
    const { connectionId } = event.connection;
    if (activeConnections.has(connectionId)) {
      activeConnections.delete(connectionId);
      //console.log("A user left the session.");
      setUserCount((prevCount) => Math.max(prevCount - 1, 0)); // Ensure count doesn't go below 0
    }
  });
  // Add Signal Listener (Prevent Multiple Attachments)
  if (!isSignalListenerAdded) {
    isSignalListenerAdded = true;

    session.on("signal", (event) => {
     if (event.type === "signal:product_selected") {
        console.log("Product Selected:", event.data);
        setSelectedProductId(event.data);
      } else if (event.type === "signal:like_signal") {
        console.log("Like Signal Received:", event.data);
        const { count } = JSON.parse(event.data);
        setUserLikeCount(count);
      } else {
        console.warn("Unknown signal type:", event.type);
      }
    });

    console.log("Signal listener attached.");
  }

  // Connect to the session
  session.connect(token, (error) => {
    if (error) {
      console.error("Error connecting to the session:", error.message);
    } else {
      console.log("Connected to the session successfully.");
    }
  });

  return session;
}

// Function to send Like Signal with Throttling
let likeSignalLock = false;

export function sendLikeSignal(session, likeCount) {
  if (!session) {
    console.error("Session not initialized yet.");
    return;
  }

  if (likeSignalLock) return; // Prevent duplicate calls

  likeSignalLock = true; // Lock for 500ms
  session.signal(
    {
      type: "like_signal",
      data: JSON.stringify({ count: likeCount }),
    },
    (error) => {
      if (error) {
        console.error("Error sending like signal:", error.message);
      } else {
        console.log("Like signal sent successfully!");
      }
    }
  );

  setTimeout(() => {
    likeSignalLock = false; // Reset lock
  }, 500);
}




// End the broadcast session
export function endBroadcast() {
  if (session) {
    session.disconnect();
    session = null; // Clean up session
    console.log('Broadcast session ended.');
  }
}

// End the call
export async function endCall() {
  clearTimeout(timer);
  endSession();
}

// End session function
async function endSession() {
  if (session) {
    session.disconnect();
  } else {
    console.log("Session already disconnected");
  }
}

// Callback handler
function handleCallback(error) {
  if (error) {
    console.error("Error: " + error.message);
  } else {
    console.log("Callback success");
  }
}

// API call to end the call
async function callEndApi() {
  const callUUID = customerCallUUID;
  const retailerName = customerRetailerName;

  try {
    const response = await fetch(`${saroAPI}/endCall`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "retailerName": retailerName,
        "callUUID": callUUID,
        "callEndedBy": 0
      }),
    });

    if (!response.ok) {
      console.error('API Error:', response.statusText);
    }
  } catch (error) {
    console.error('API Crash Error:', error.message);
  }
}

// Function to toggle video
export function toggleVideo(isVideoOn) {
  if (!isVideoEnabled) {
    return; // Prevent video from being toggled if it's disabled
  }
  publisher.publishVideo(!isVideoOn);
}

// Function to toggle microphone
export function toggleMicrophone(isMicrophoneOn) {
  publisher.publishAudio(!isMicrophoneOn);
}

// Function to set the handleVideoToggle reference
export function setHandleVideoToggleRef(handleVideoToggle) {
  handleVideoToggleRef = handleVideoToggle;
}
