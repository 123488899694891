import React, { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import "./GoLiveChat.css"; // Ensure this file exists for custom styles
import { useData } from '../../Context.js'; // Import useData


function GoLiveChat({ showID, mobileView = false, isInputVisible = false }) {
  const [username, setUsername] = useState(""); // Auto-generated username
  const [messages, setMessages] = useState([]); // Messages state
  const [input, setInput] = useState(""); // Message input
  const { data } = useData(); // Access global data

  const socket = useRef(null);
  const chatBodyRef = useRef(null);
  const clerkID = data.clerkID; // Retrieve clerkID


  // Auto-scroll to bottom
  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  // Auto-generate username and room
  // useEffect(() => {
  //   const userCount = parseInt(localStorage.getItem("userCount") || "0", 10) + 1;
  //   const autoUsername = `User ${userCount}`;
  //   localStorage.setItem("userCount", userCount);
  //   setUsername(autoUsername);

  //   socket.current = io("https://chat-app-766991091103.us-central1.run.app", {
  //     transports: ["websocket"],
  //   });

  //   socket.current.on("connect", () => {
  //     console.log("🔗 Connected to Socket.IO Server");

  //     // Auto-join the room
  //     socket.current.emit("signin", { user: autoUsername, room: showID }, (error, history) => {
  //       if (error) {
  //         console.error("❌ Signin error:", error);
  //       } else if (history?.messages) {
  //         addHistory(history.messages);
  //       }
  //     });
  //   });

  //   socket.current.on("message", (msg) => {
  //     setMessages((prev) => [
  //       ...prev,
  //       {
  //         user: msg.user,
  //         text: msg.text,
  //         isSelf: msg.user === autoUsername, // Logic for checking if the user sent the message
  //       },
  //     ]);
  //     scrollToBottom();
  //   });

  //   return () => {
  //     socket.current.disconnect();
  //   };
  // }, [showID]);

  const addHistory = (historyMessages) => {
    setMessages(
      historyMessages.map((msg) => ({
        user: msg.user,
        text: msg.text,
        isSelf: msg.user === username, // Check if the message is from the user
      }))
    );
    scrollToBottom();
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    // Emit the message to the server
    socket.current.emit("sendMessage", input, (error) => {
      if (error) {
        console.error("❌ Failed to send message:", error);
      }
    });

    setInput(""); // Clear the input
  };

  // Limit to last 3 messages in mobile view
  const displayedMessages = mobileView
    ? messages // Only show the last 3 messages
    : messages;

  return (
    <>
      {/* Chat Messages */}
      <div
        className={`inline-chat-messages ${mobileView ? "mobile-chat" : ""}`}
        ref={chatBodyRef}
      >
{displayedMessages.map((msg, index) => (
  <div
    key={index}
    className={`inline-chat-message-container ${ msg.isSelf ? "sent" : msg.user === clerkID ? "highlighted" : "received"}`}
  >
    {/* Username shown above the message */}
    <div className="chat-username">{msg.user}</div>

    {/* Message with background color */}
    <div className={`inline-chat-message ${ msg.isSelf ? "sent" : msg.user === clerkID ? "highlighted" : "received"}`}>
      <span>{msg.text}</span>
    </div>
  </div>
))}

      </div>

      {/* Chat Input */}
{/* Chat Input */}
{(mobileView && isInputVisible) || !mobileView ? ( // Show input box in mobile when toggled
  <form className="inline-chat-input" onSubmit={handleSendMessage}>
    <input
      type="text"
      placeholder="Enter your comment..."
      value={input}
      onChange={(e) => setInput(e.target.value)}
      required
    />
    <button type="submit">➤</button>
  </form>
) : null}

    </>
  );
}

export default GoLiveChat;
