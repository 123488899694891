// src/utils/indexedDBHelper.js

const DB_NAME = 'MediaCacheDB';
const STORE_NAME = 'mediaFiles';
const DB_VERSION = 1;

/**
 * Open IndexedDB database and create object store if needed
 */
export const openDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'url' });
            }
        };

        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject('IndexedDB failed to open.');
    });
};

/**
 * Store a media file (video or image) in IndexedDB
 * @param {string} url - The media URL
 */
export const cacheMedia = async (url) => {
    const db = await openDatabase().catch(() => null);

    if (!db) {
        console.warn("IndexedDB not available, storing in localStorage.");
        localStorage.setItem(url, url); // Store only metadata
        return;
    }

    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const transaction = db.transaction(STORE_NAME, 'readwrite');
            const store = transaction.objectStore(STORE_NAME);
            store.put({ url, blob });
        })
        .catch(err => console.error("Failed to cache media:", err));
};


/**
 * Retrieve a cached media file from IndexedDB
 * @param {string} url - The media URL
 * @returns {Promise<string>} - A blob URL or rejection if not found
 */
export const getCachedMedia = async (url) => {
    const db = await openDatabase().catch(() => null); // ✅ Handle IndexedDB failure

    if (!db) {
        console.warn("IndexedDB not available, checking localStorage.");
        return localStorage.getItem(url) || null; // ✅ Fallback to localStorage
    }

    return new Promise((resolve, reject) => {
        const transaction = db.transaction(STORE_NAME, 'readonly');
        const store = transaction.objectStore(STORE_NAME);
        const request = store.get(url);

        request.onsuccess = () => {
            if (request.result) {
                resolve(URL.createObjectURL(request.result.blob));
            } else {
                reject("No cached media found.");
            }
        };
        request.onerror = () => reject("Failed to retrieve media.");
    });
};


/**
 * Clear old cached media (if needed)
 */
export const clearCachedMedia = async () => {
    const db = await openDatabase();
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    store.clear();
    console.log('✅ Cached media cleared.');
};
