import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "./CarouselFullScreen.css";
import { imageUrl, saroAPI } from '../constant.js';
import { retailerNAME } from '../constant.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisH, faVolumeUp, faVolumeMute, faMinusCircle, faPlusCircle, faExpand, faCompress, faPlay, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useData } from '../../Context.js';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { cacheMedia, getCachedMedia } from '../../utils/indexedDBHelper';


const CarouselFullScreen = () => {
    const { data } = useData();
    const [cart, setCart] = useState([]); // State to manage the cart
    const [loadingProduct, setLoadingProduct] = useState(null); // Manage loader for a specific product
    const hardCodedToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";
    const [videos, setVideos] = useState([]); // State for videos
    const videoRefs = useRef([]); // Dynamic refs for multiple videos
    const sliderRef = useRef(null); // Ref for the Slider
    const [isPlaying, setIsPlaying] = useState(true); // State to track play/pause
    const [isMuted, setIsMuted] = useState(true);
    const [activeSlide, setActiveSlide] = useState(0); // Initialize active slide to the first video
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null); // For modal
    const [viewerId, setViewerId] = useState(null); // Add this state
    const [activeVideoId, setActiveVideoId] = useState(null); // Store active video ID
    const [activeVideoIndex, setActiveVideoIndex] = useState(0);
    const touchStartY = useRef(null); // ✅ Track swipe gestures
    const [isFullScreen, setIsFullScreen] = useState(true); // ✅ Track full-screen mode
    const [nextPageToken, setNextPageToken] = useState(null); // ✅ Track full-screen mode
    const [hasMoreVideos, setHasMoreVideos] = useState(true); // ✅ Controls further API calls & UI
    const [loadingStates, setLoadingStates] = useState({});
    const { videoID } = useParams(); // ✅ Get videoID from URL
    const location = useLocation();
    const preFetchedVideos = location.state?.preFetchedVideos || [];
    const [isFirstTimeFetching, setIsFirstTimeFetching] = useState(false);
    const [isShopifyConnected, setIsShopifyConnected] = useState(false);
    const [isWooCommerceConnected, setIsWooCommerceConnected] = useState(false);
    const [isInnerSwiping, setIsInnerSwiping] = useState(false);
    const outerSliderRef = useRef(null);

    useEffect(() => {
        //console.log('activeVideoIndex updated');
        fetchProductsForCurrentVideo();
    }, [activeVideoIndex]); // ✅ Runs when video changes OR videos are loaded

    // ✅ New useEffect to fetch products for the first video when videos are initially loaded
    useEffect(() => {
        //console.log('videos updated with length ', videos.length);
        if (videos.length > 0) {
            setActiveVideoId(videos[0].videoID); // ✅ Set the first video as active
            if (!isFirstTimeFetching) {
                setIsFirstTimeFetching(true);
                fetchProductsForCurrentVideo();

                //Call these first time API after 3 seconds to avoid internet cloting
                setTimeout(() => {
                    //This will fetch for the next video
                    fetchVideos(); // ✅ Call API after 3 seconds
                }, 3000);
            }
        }
    }, [videos]);

    const goToFullscreen = () => {
        //console.log('Full Screen called');
        navigate("Components/CarouselFullScreen"); // Redirect to Fullscreen Component
    };

    useEffect(() => {
        //console.log('Setting Videos');
        setVideos([preFetchedVideos]);

        setNextPageToken(videoID);

        //setActiveVideoIndex(0);

        // console.log('NextPageToken: ', nextPageToken);
        // console.log('videoID after parsed: ', videoID);

        // if (!isFirstTimeFetching) {
        //     setIsFirstTimeFetching(true); // ✅ Mark as fetched
        //     fetchVideos();
        //     setActiveVideoIndex(0);
        //     fetchProductsForCurrentVideo();
        // } else {
        //     console.log('Twice fetch videos api call ignored')
        // }

        handleFullscreen();
    }, []); // ✅ Ensure dependencies are passed if needed

    useEffect(() => {
        //console.log('activeVideoIndex updated to fetchVideos');
        //console.log('ActiveVideoIndex updated with value', activeVideoIndex, hasMoreVideos, videos.length);
        if (activeVideoIndex === videos.length - 1 && hasMoreVideos) {
            fetchVideos(); // ✅ Fetch more videos only if available
        }
    }, [activeVideoIndex]);

    const fetchProductsForCurrentVideo = async () => {
        if (!videos[activeVideoIndex]) return;

        const videoShowId = videos[activeVideoIndex].videoID; // Get current video ID

        // ✅ Avoid duplicate API calls for already fetched products
        if (products[videoShowId]) {
            //console.log(`⏩ Skipping API call, products already fetched for Video ID: ${videoShowId}`);
            return;
        }

        const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";

        try {
            const response = await fetch(
                `${saroAPI}/fetchVideoShowProducts?videoShowId=${videoShowId}&retailerName=${retailerNAME}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.ok) {
                const responseData = await response.json();
                //console.log(`🛍️ Products for Video ID ${videoShowId}:`, responseData);

                if (responseData.products) {
                    setProducts(prevProducts => ({
                        ...prevProducts,
                        [videoShowId]: responseData.products // ✅ Store in state to prevent duplicate API calls
                    }));
                    setIsShopifyConnected(responseData.isShopifyConnected);
                    setIsWooCommerceConnected(responseData.isWooCommerceConnected);
                }
            } else {
                //console.error("❌ Failed to fetch products:", response.statusText);
            }
        } catch (error) {
            //console.error("❌ Error fetching products:", error.message);
        }
    };

    const handleAddToCart = async (product) => {
        //console.log('Add to cart button pressed');

        const videoId = product.videoId || selectedProduct?.videoId;

        if (!videoId) {
            //console.error("🚨 Missing videoId!");
            alert("Error: Missing videoId!");
            return;
        }

        if (!product.id) {
            //console.error("🚨 Missing product ID!", product);
            alert("Error: Missing Product ID!");
            return;
        }

        setLoadingProduct(product.id);
        try {
            console.log("🔍 Debug - Product ID:", product.id, "Type:", typeof product.id);

            const productId = typeof product.id === 'string' ? product.id.split('/').pop() : String(product.id);
            const selectedVariantId = product.selectedVariant || product.variants?.[0]?.id;

            if (!selectedVariantId) {
                alert("No variant selected or available.");
                return;
            }

            // ✅ Get cartId from cookies (if exists)
            let cartId = Cookies.get('cart') || '';
            if (cartId && !cartId.startsWith('gid://shopify/Cart/')) {
                cartId = `gid://shopify/Cart/${cartId}`;
            }


            const payload = {
                retailerName: retailerNAME,
                cartId: cartId,
                videoId,
                productId,
                variantId: typeof selectedVariantId === 'string'
                    ? selectedVariantId.split('/').pop()
                    : String(selectedVariantId),
                quantity: 1,
                title: product.title,
                price: product.variants?.find((v) => v.id === selectedVariantId)?.price || product.price,
                image: product.image,
            };

            // console.log("📤 Payload Being Sent:", payload);

            const response = await fetch(`${saroAPI}/addToCart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${hardCodedToken}`,
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            // console.log("✅ Add to Cart API Response:", result);

            if (response.ok) {
                if (!cartId && result.shopifyCartId) {
                    Cookies.set('cart', result.shopifyCartId, { expires: 7 });
                    //console.log("🛍️ Saved new Cart ID in Cookies:", result.shopifyCartId);
                }

                setCart((prevCart) => {
                    const existingItem = prevCart.find((item) => item.id === product.id);
                    if (existingItem) {
                        return prevCart.map((item) =>
                            item.id === product.id
                                ? { ...item, quantity: item.quantity + 1, videoId }
                                : item
                        );
                    }
                    return [...prevCart, { ...product, quantity: 1, videoId }];
                });
            }
        } catch (error) {
            //console.error('❌ Error adding product to cart:', error);
        } finally {
            setLoadingProduct(null);
        }
    };

    // const handleRemoveFromCart = async (product) => {
    //     let cartId = Cookies.get('cart');
    //     if (!cartId) {
    //         console.warn("No cart available.");
    //         return;
    //     }
    //         if (cartId && !cartId.startsWith('gid://shopify/Cart/')) {
    //             cartId = `gid://shopify/Cart/${cartId}`;
    //         }

    //     console.log('Removing product', product);

    //     setLoadingProduct(product.id); // Start loader for this product
    //     try {
    //         //const productId = typeof product.id === 'string' ? product.id.split('/').pop() : String(product.id);
    //         let variantId = product.selectedVariant || product.variants?.[0]?.id;

    //         // ✅ Extract only the numeric part of the Shopify variant ID
    //         variantId = variantId.split('/').pop(); 

    //         const response = await fetch(`${saroAPI}/removeFromCart`, {
    //             method: 'DELETE',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${hardCodedToken}`,
    //             },
    //             body: JSON.stringify({
    //                 retailerName: retailerNAME,
    //                 cartId, // Use stored viewer ID
    //                 variantId,
    //                 quantity: 1,

    //             }),
    //         });

    //         if (response.ok) {
    //             setCart((prevCart) => {
    //                 const existingItem = prevCart.find((item) => item.id === product.id);
    //                 if (existingItem.quantity === 1) {
    //                     return prevCart.filter((item) => item.id !== product.id);
    //                 }
    //                 return prevCart.map((item) =>
    //                     item.id === product.id
    //                         ? { ...item, quantity: item.quantity - 1 }
    //                         : item
    //                 );
    //             });
    //         }
    //     } catch (error) {
    //         console.error('Error removing product from cart:', error);
    //     } finally {
    //         setLoadingProduct(null); // Stop loader
    //     }
    // };

    const handleCheckout = async (videoId) => {
        if (cart.length === 0) {
            alert("Your cart is empty!");
            return;
        }

        let cartId = Cookies.get('cart');
        if (!cartId) {  // ✅ Now checking the updated viewerId stored from Add to Cart API
            //console.error("🚨 Checkout Error: Viewer ID is missing!");
            alert("Error: Viewer ID is missing. Please add an item to the cart first.");
            return;
        }
        if (cartId && !cartId.startsWith('gid://shopify/Cart/')) {
            cartId = `gid://shopify/Cart/${cartId}`;
        }

        if (!videoId) {
            //console.error("🚨 Checkout Error: Missing videoId!");
            alert("Error: Missing videoId for checkout.");
            return;
        }

        try {
            const response = await fetch(`${saroAPI}/checkout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${hardCodedToken}`,
                },
                body: JSON.stringify({
                    retailerName: retailerNAME,
                    cartId,
                    videoId,
                }),
            });

            const result = await response.json();
            // console.log("✅ Checkout API Response:", result);

            if (response.ok) {
                if (result.checkoutUrl) {
                    window.location.href = result.checkoutUrl;
                } else {
                    alert("Checkout failed.");
                }
            } else {
                //console.error("❌ Failed to initiate checkout:", result);
            }
        } catch (error) {
            //console.error("❌ Error during checkout:", error);
        }
    };

    const isInCart = (product) => {
        return cart.some((cartItem) => cartItem.id === product.id);
    };


    const toggleMuteUnmute = () => {
        setIsMuted((prevMuted) => !prevMuted);
    };

    const togglePlayPause = (event, index) => {
        if (index !== activeVideoIndex) return; // ✅ Only allow play/pause for the active video

        const videoElement = videoRefs.current[index]; // ✅ Get the video element
        if (!videoElement) return; // ✅ Prevent errors if ref is undefined

        const clickY = event.clientY;
        const clickX = event.clientX;
        const videoRect = videoElement.getBoundingClientRect();
        const videoHeight = videoElement.clientHeight;
        const videoWidth = videoElement.clientWidth;
        const isMobile = window.innerWidth <= 768; // ✅ Check if it's mobile view

        // ✅ Define Exclusion Zones (Top 20%, Bottom 20%, Left 20%, Right 20%)
        const topThreshold = videoRect.top + videoHeight * 0.2;
        const bottomThreshold = videoRect.top + videoHeight * 0.8;
        const leftThreshold = videoRect.left + videoWidth * 0.2;
        const rightThreshold = videoRect.left + videoWidth * 0.8;

        if (isMobile) {
            // ✅ Only allow play/pause if clicked in the middle 60% (vertically & horizontally)
            if (clickY > topThreshold && clickY < bottomThreshold && clickX > leftThreshold && clickX < rightThreshold) {
                setIsPlaying((prevIsPlaying) => {
                    const newPlayingState = !prevIsPlaying;
                    newPlayingState ? videoElement.play() : videoElement.pause();
                    return newPlayingState;
                });
            }
        } else {
            // ✅ For web view, allow play/pause on the **entire video**
            setIsPlaying((prevIsPlaying) => {
                const newPlayingState = !prevIsPlaying;
                newPlayingState ? videoElement.play() : videoElement.pause();
                return newPlayingState;
            });
        }
    };

    useEffect(() => {
        // Pause all videos except the active one
        videoRefs.current.forEach((video, index) => {
            if (video) {
                if (index === activeVideoIndex) {
                    video.muted = true;
                    setIsMuted(true);
                    video.play(); // Play the current active video
                } else {
                    video.pause(); // Pause other videos
                    video.currentTime = 0; // Reset time to 0 for better UX
                }
            }
        });
    }, [activeVideoIndex]);

    const filterVideosFromJson = (providedJson, videoID) => {
        if (!providedJson || !Array.isArray(providedJson)) {
            //console.error("Invalid video JSON provided");
            return;
        }

        //console.log("Filtering videos from provided JSON, removing those before videoID:", videoID);

        // ✅ Find index of the videoID in the provided JSON
        const index = providedJson.findIndex(video => video.videoID === videoID);

        if (index === -1) {
            //console.warn("Provided videoID not found in the JSON, returning all videos.");
            return providedJson; // Return full list if videoID is not found
        }

        // ✅ Keep only videos from the given `videoID` onwards
        const filteredVideos = providedJson.slice(index);

        // ✅ Apply the same sorting logic as the original API method
        const sortedVideos = filteredVideos
            .filter(video => video.status !== 0) // ✅ Exclude inactive videos
            .sort((a, b) => {
                if (a.status === "active" && b.status !== "active") return -1;
                if (a.status !== "active" && b.status === "active") return 1;
                return a.orderBy - b.orderBy;
            });

        // ✅ Update the video state with the new filtered and sorted videos
        setVideos(sortedVideos);

        //console.log("Filtered videos after removing previous ones:", sortedVideos);
    };

    const handleShopNowClick = async (product, videoId) => {
        // console.log("🛍️ Shop Now Clicked for:", product.title);
        // console.log("🎥 Associated Video ID:", videoId);

        // ✅ Open the modal immediately (before API call)
        setSelectedProduct({
            ...product,
            variants: product.variants || [],
            selectedVariant: product.variants?.[0]?.id || null,
            videoId,
        });

        document.body.classList.add("modal-open"); // ✅ Open modal instantly

        // API Payload
        const payload = {
            retailerName: retailerNAME,
            videoShowId: videoId,
            eventType: "shopNowClick",
        };

        // ✅ API call runs asynchronously in the background
        fetch(`${saroAPI}/logShoppableEvent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${hardCodedToken}`,
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(result => console.log())
            .catch(error => console.error());
    };



    const closeModal = () => {
        setSelectedProduct(null); // Close modal
        document.body.classList.remove("modal-open"); // Remove class when modal closes
    };

    const CustomPrevArrow = ({ onClick }) => {
        return (
            <div
                className={`custom-prev-arrow ${window.innerWidth <= 768 ? "show-in-mobile" : ""}`}
                onClick={() => {
                    closeModal();
                    onClick();
                }}
            >
                &#10094;
            </div>
        );
    };

    const CustomNextArrow = ({ onClick }) => {
        return (
            <div
                className={`custom-next-arrow ${window.innerWidth <= 768 ? "show-in-mobile" : ""}`}
                onClick={() => {
                    closeModal();
                    onClick();
                }}
            >
                &#10095;
            </div>
        );
    };


    useEffect(() => {
        // console.log('🔄 Video changed, updating active video');



        // Call API **without waiting** for video to play
        //logVideoClickEvent();

        // Update video playback logic
        // videoRefs.current.forEach((videoEl, idx) => {
        //     if (!videoEl) return;

        //     if (idx === activeVideoIndex) {
        //         videoEl.play();  // ✅ Start playing new video immediately
        //     } else {
        //         videoEl.pause();
        //         videoEl.currentTime = 0;
        //     }
        // });
    }, [activeVideoIndex]);

    // Call the API without affecting video playback
    const logVideoClickEvent = async () => {
        if (!videos[activeVideoIndex]) return;

        const payload = {
            retailerName: retailerNAME,  // Ensure retailer name is set
            videoShowId: videos[activeVideoIndex].videoID,
            eventType: "newVideoClick",
        };

        try {
            const response = await fetch(`${saroAPI}/logShoppableEvent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${hardCodedToken}`,
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            // console.log("✅ logShoppableEvent API Response:", result);
        } catch (error) {
            // console.error("❌ Error logging video change event:", error);
        }
    };

    useEffect(() => {
        //console.log('activeVideoIndex updated to debounce');
        // console.log('🔄 Video changed, updating active video');

        // Call the API without affecting video playback


        // Call API **without waiting** for video to play
        if (videos[activeVideoIndex]) {
            debouncedLogEvent(videos[activeVideoIndex]); // ✅ Only calls API if user stops swiping
        }
    }, [activeVideoIndex]);

    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    const debouncedLogEvent = debounce(logVideoClickEvent, 3000); // 500ms delay

    const fetchVideos = async () => {
        if (!hasMoreVideos) return; // ✅ Stop fetching if no more videos

        //console.log('Calling getShopableVideos API with token: ', nextPageToken);
        let apiUrl = `${saroAPI}/getShopableVideos?retailerName=${retailerNAME}&pageSize=1`;

        if (nextPageToken) {
            apiUrl += `&pageToken=${nextPageToken}`; // ✅ Only add pageToken if available
        }

        try {
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${hardCodedToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();

                // ✅ If server returns no videos, stop further API calls
                if (!data.data) {
                    //console.log("No more videos available.");
                    setHasMoreVideos(false); // ✅ Disable further fetching
                    return;
                }

                const sortedVideos = data.data.data
                    .filter(video => video.status !== 0) // ✅ Exclude inactive videos
                    .sort((a, b) => {
                        if (a.status === "active" && b.status !== "active") return -1;
                        if (a.status !== "active" && b.status === "active") return 1;
                        return a.orderBy - b.orderBy;
                    });

                //console.log('Previous Video Count: ', videos.length);

                setVideos(prevVideos => {
                    const mergedVideos = [...prevVideos, ...sortedVideos];

                    return mergedVideos
                        // .filter((video, index, self) =>
                        //     index === self.findIndex(v => v.videoID === video.videoID) // ✅ Remove duplicates
                        // )
                        .sort((a, b) => {
                            if (a.status === "active" && b.status !== "active") return -1;
                            if (a.status !== "active" && b.status === "active") return 1;
                            return a.orderBy - b.orderBy;
                        });
                });

                setNextPageToken(data.data.nextPageToken || null); // ✅ Stop calling API when no more pageToken

                if (!nextPageToken) {
                    sliderRef.current.slickGoTo(0);
                }

                sortedVideos.forEach(video => {
                    cacheMedia(video.videoUrl);
                });
            } else {
                //console.error("Failed to fetch videos:", response.statusText);
            }
        } catch (error) {
            //console.error("Error fetching videos:", error);
        }
    };

    const innerSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    beforeChange: () => setIsInnerSwiping(true),  // Prevent outer slider movement
    afterChange: () => setIsInnerSwiping(false),  // Re-enable outer slider swipe
    };


    const sliderSettings = {
        dots: false,
        infinite: false, // Disable infinite scrolling
        speed: 500,
        slidesToShow: 2, // Adjust based on design needs
        slidesToScroll: 1,
        swipe: !isInnerSwiping, // Disable swipe when inner slider is swiping
        // Disable center mode to prevent showing partial slidesuseE
        // If you want to keep centerMode, set centerPadding to '0px'
        //centerMode: true,
        //centerPadding: '0px',
        autoplay: false,
        autoplaySpeed: 4000,
        vertical: false,
        initialSlide: 0,

        afterChange: (current) => {
            // console.log(`Current active slide: ${current}`);
            // setActiveSlide(current);
            setActiveVideoIndex(current);
            setIsPlaying(true);
            setSelectedProduct(null);
            document.body.classList.remove("modal-open"); // Ensure modal class is removed
            setShowOptions(false);

        },
        // Optional: Customize arrows if needed
        nextArrow: (activeVideoIndex < videos.length - 1 || hasMoreVideos) ? <CustomNextArrow /> : null,
        prevArrow: activeVideoIndex > 0 ? <CustomPrevArrow /> : null,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    arrows: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    lazyLoad: true,
                    slidesToShow: 1, // Full-screen video
                    centerMode: false,
                    centerPadding: '0px',
                    slidesToScroll: 1,
                    arrows: true, // Remove arrows for better mobile UX
                    //swipeToSlide: true, // Enable touch-based swiping
                    touchThreshold: 25, // Responsive touch sensitivity
                    draggable: true, // Ensure smooth vertical scrolling
                },
            },
        ],
    };

    const handleTouchStart = (event) => {
        if (isFullScreen) {
            touchStartY.current = event.touches[0].clientY;
            // console.log("🛑 Swipe detected – closing product modal...");
            setSelectedProduct(null);  // ✅ Close modal immediately
            document.body.classList.remove("modal-open");
        }
    };

    const handleTouchMove = (event) => {
        if (!isFullScreen || touchStartY.current === null) return;

        const touchEndY = event.touches[0].clientY;
        const deltaY = touchStartY.current - touchEndY;

        if (Math.abs(deltaY) > 50) {
            // Swipe Up → Next Video
            if (deltaY > 0 && activeVideoIndex < videos.length - 1) {
                switchToVideo(activeVideoIndex + 1);
            }
            // Swipe Down → Previous Video
            else if (deltaY < 0 && activeVideoIndex > 0) {
                switchToVideo(activeVideoIndex - 1);
            }

            touchStartY.current = null;
        }
    };

    // ✅ Function to smoothly switch videos

    const switchToVideo = (newIndex) => {
        // // console.log("🎥 Switching to Video:", newIndex);

        // // ✅ Close product modal first
        // setSelectedProduct(null);
        // document.body.classList.remove("modal-open");

        // setTimeout(() => {
        //     setActiveVideoIndex(newIndex);
        //     sliderRef.current.slickGoTo(newIndex); // ✅ Now switch videos

        //     videoRefs.current.forEach((video, index) => {
        //         if (video) {
        //             if (index === newIndex) {
        //                 video.play();
        //             } else {
        //                 video.pause();
        //                 video.currentTime = 0;
        //             }
        //         }
        //     });
        // }, 50); // ✅ Shorter delay for smoother UX
    };

    const handleFullscreen = () => {
        const videoContainer = document.querySelector(".slick-slider,.slick-initialized");

        if (!videoContainer) return; // Ensure element exists

        // Check if fullscreen API is supported (Only for browsers that allow it)
        if (document.fullscreenElement || document.webkitFullscreenElement) {
            // Exit Fullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }

            // const preventReload = (e) => e.preventDefault();
            // window.addEventListener("beforeunload", preventReload);
            // setTimeout(() => {
            //     window.removeEventListener("beforeunload", preventReload);
            // }, 1000);

            // Restore scrolling
            document.body.style.overflow = "auto";

            // Show Shopify navigation (if exists)
            const shopifyNav = document.getElementById("shopify-nav");
            if (shopifyNav) {
                shopifyNav.style.display = "block";
            }

        } else {
            // **iOS WebView Workaround (Simulated Fullscreen)**
            if (navigator.userAgent.includes("iPhone") || navigator.userAgent.includes("iPad")) {
                videoContainer.style.position = "fixed";
                videoContainer.style.top = "0";
                videoContainer.style.left = "0";
                videoContainer.style.width = "100dvw";
                videoContainer.style.height = "100dvh";
                videoContainer.style.bottom = "15vh";
                videoContainer.style.zIndex = "9999";
                videoContainer.style.backgroundColor = "black";

                // Hide Shopify navigation (if exists)
                const shopifyNav = document.getElementById("shopify-nav");
                if (shopifyNav) {
                    shopifyNav.style.display = "none";
                }

                // **Disable scrolling**
               // document.body.style.overflow = "hidden";
            } else {
                // Standard Fullscreen Mode for Supported Browsers
                if (videoContainer.requestFullscreen) {
                    videoContainer.requestFullscreen();
                } else if (videoContainer.mozRequestFullScreen) {
                    videoContainer.mozRequestFullScreen();
                } else if (videoContainer.webkitRequestFullscreen) {
                    videoContainer.webkitRequestFullscreen();
                } else if (videoContainer.msRequestFullscreen) {
                    videoContainer.msRequestFullscreen();
                }

                // Disable scrolling when in fullscreen mode
                //document.body.style.overflow = "hidden";
            }
        }
    };


    useEffect(() => {
        const handleFullscreenChange = () => {
            const isFull = !!document.fullscreenElement;
            setIsFullScreen(isFull);

            if (isFull) {
                document.body.classList.add("fullscreen-mode");
            } else {
                document.body.classList.remove("fullscreen-mode");
            }
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.body.classList.remove("fullscreen-mode"); // Ensuring cleanup on unmount
        };
    }, []);


    useEffect(() => {
        const handleIntersection = (entries) => {
            // entries.forEach((entry) => {
            //     const videoElement = entry.target;
            //     const videoHeight = videoElement.offsetHeight; // Get video height
            //     const remainingVisibleHeight = entry.boundingClientRect.bottom - entry.boundingClientRect.top;
            //     const viewportHeight = window.innerHeight;

            //     // ✅ Ensure video stops ONLY when less than 10% is visible
            //     if (remainingVisibleHeight <= videoHeight * 0.1 || entry.intersectionRatio <= 0.1) {
            //         videoElement.pause();
            //     } else {
            //         videoElement.play().catch((error) => console.warn("Autoplay prevented:", error));
            //     }
            // });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null, // Viewport
            rootMargin: "0px", // No extra margins
            threshold: [0.5], // ✅ Stops only when less than 10% is visible
        });

        videoRefs.current.forEach((video) => {
            if (video) observer.observe(video);
        });

        return () => {
            videoRefs.current.forEach((video) => {
                if (video) observer.unobserve(video);
            });
        };
    }, []);


    useEffect(() => {
        // const checkVideoVisibility = () => {
        //     videoRefs.current.forEach((video, index) => {
        //         if (video) {
        //             const rect = video.getBoundingClientRect();
        //             const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

        //             if (isVisible) {
        //                 video.play().catch(error => console.warn("Autoplay prevented:", error));
        //             } else {
        //                 video.pause();
        //             }
        //         }
        //     });
        // };

        // window.addEventListener("scroll", checkVideoVisibility);
        // return () => window.removeEventListener("scroll", checkVideoVisibility);
    }, []);

    const handleVideoLoad = (videoID) => {
        setLoadingStates(prev => ({
            ...prev,
            [videoID]: false, // Mark this specific video as loaded
        }));
    };

    const [showOptions, setShowOptions] = useState(false);
    // Toggle popup visibility
    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };


    const navigate = useNavigate();

    const goTov = () => {
        document.body.style.overflow = "auto";
        navigate(-1);
    };

    useEffect(() => {
        const handleBack = () => {
          navigate(-1); // Go back in history
        };
    
        window.addEventListener("popstate", goTov);
    
        return () => {
          window.removeEventListener("popstate", goTov);
        };
      }, [navigate]);

    return (
        // <div>

        // </div>
        <div className="video-carosel-fs">

            {/* Updated Slider Settings */}
            {/* {console.log("Rendering video carousel with videos:", videos)} */}
            <Slider  {...sliderSettings} ref={outerSliderRef}>
                {videos.map((video, index) => {
                    //console.log(`Rendering video #${index} with ID: ${video.videoID}`);
                    //console.log(`Video data ${index} : `,video);
                    const isActive = index === activeVideoIndex;
                    const isLoading = loadingStates[video.videoID] !== false;

                    return (
                        <div key={video.videoID} className="video-slide-fs" >
                            {/* Updated Video Section */}
                            <div className="video-container-fs">
                                <div className={`video-wrapper-fs ${isFullScreen ? "fullscreen-active" : ""}`}>
                                    <div className="video-top-blur-fs"></div>
                                    {/* <video
                                        className="carousel-video"
                                        poster={video.thumbnail}
                                        src={isActive ? video.videoUrl : undefined}
                                        preload="metadata"
                                        autoPlay={isActive}
                                        loop
                                        muted={isMuted}
                                        controls

                                        ref={(el) => (videoRefs.current[index] = el)} // Correctly assign ref for each video
                                        onClick={() => {
                                            console.log(`Video ${index} clicked`);
                                            sliderRef.current.slickGoTo(index);
                                        }}
                                        onTouchStart={handleTouchStart} // ✅ Detect swipe start
                                        onTouchMove={handleTouchMove} // ✅ Detect swipe movement
                                    ></video> */}

                                    {window.innerWidth <= 1024 ? (
                                        // Mobile View (Show only when isActive)
                                        //  isActive && (
                                        <video
                                            className={`carousel-video-fs ${loadingStates[video.videoID] === false ? "loaded" : ""}`} 
                                            key={video.videoUrl}
                                            src={video.videoUrl}
                                            //src={isActive ? 'https://firebasestorage.googleapis.com/v0/b/wakapapa-fd7cb.appspot.com/o/retailerUpload%2Fpepsi%2F1-25%2Fc235e86e-72bf-4fb1-a973-363847c424c8-quinn_i6tbapxcxwu1pim6d8tal35d.mp4?alt=media&token=4413f084-a48c-48b9-9278-25815696404a' : ''}
                                            //src={'https://firebasestorage.googleapis.com/v0/b/wakapapa-fd7cb.appspot.com/o/retailerUpload%2Fpepsi%2F1-25%2Fc235e86e-72bf-4fb1-a973-363847c424c8-quinn_i6tbapxcxwu1pim6d8tal35d.mp4?alt=media&token=4413f084-a48c-48b9-9278-25815696404a'}
                                            autoPlay={index === activeVideoIndex}
                                            playsInline
                                            loop
                                            muted={isMuted}
                                            ref={(el) => (videoRefs.current[index] = el)} // Assign ref
                                            onClick={(event) => {
                                                togglePlayPause(event, index);
                                                // if (index === activeVideoIndex) {
                                                //     // ✅ If clicking on the active video, toggle play/pause
                                                //     togglePlayPause(event, index);
                                                // } else {
                                                //     // ✅ If clicking on another slide, switch to that slide
                                                //     sliderRef.current.slickGoTo(index);
                                                //     setActiveVideoIndex(index);
                                                //     setIsPlaying(true);
                                                // }

                                                //goToFullscreen();
                                            }}
                                            // onTouchStart={handleTouchStart}
                                            // onTouchMove={handleTouchMove}
                                            loading="lazy" // ✅ Lazy loading
                                            preload={isActive ? "auto" : "metadata"}
                                            onLoadedData={() => {
                                                handleVideoLoad(video.videoID);
                                                setLoadingStates(prev => ({ ...prev, [video.videoID]: false })); // Mark video as loaded
                                            }}
                                            onError={async (e) => {
                                                try {
                                                    const cachedUrl = await getCachedMedia(video.videoUrl);
                                                    e.target.src = cachedUrl;
                                                } catch {
                                                    console.warn(`No cached version found for ${video.videoUrl}`);
                                                }
                                            }}
                                        // onLoadedData={(e) => {
                                        //     e.target.style.backgroundImage = "none"; // ✅ Remove poster when video loads
                                        // }}
                                        // onWaiting={(e) => {
                                        //     e.target.style.backgroundImage = `url(${video.thumbnail})`; // ✅ Show poster while video is loading
                                        // }}
                                        // style={{
                                        //     backgroundImage: `url(${video.thumbnail})`, // ✅ Force poster for iOS
                                        //     backgroundSize: "cover",
                                        //     backgroundPosition: "center",
                                        // }}
                                        />
                                        //)
                                    ) : (
                                        // Desktop View (Always Show Video)
                                        <video
                                            className="carousel-video-fs"
                                            key={video.videoUrl}
                                            src={video.videoUrl}
                                            autoPlay={false}
                                            playsInline
                                            loop
                                            muted={isMuted}
                                            ref={(el) => (videoRefs.current[index] = el)} // Assign ref
                                            onClick={(event) => {
                                                if (index === activeVideoIndex) {
                                                    // ✅ If clicking on the active video, toggle play/pause
                                                    togglePlayPause(event, index);
                                                } else {
                                                    // ✅ If clicking on another video, switch to that slide and play the video
                                                    sliderRef.current.slickGoTo(index);
                                                    setActiveVideoIndex(index);
                                                    setIsPlaying(true);

                                                    // ✅ Ensure newly active video starts playing
                                                    setTimeout(() => {
                                                        videoRefs.current[index]?.play();
                                                    }, 100); // ✅ Small delay ensures video is ready to play
                                                }
                                            }}
                                            onTouchStart={handleTouchStart}
                                            onTouchMove={handleTouchMove}
                                            loading="lazy" // ✅ Lazy loading
                                            preload="auto"
                                            onLoadedData={(e) => {
                                                e.target.style.backgroundImage = "none"; // ✅ Remove poster when video loads
                                            }}
                                            onWaiting={(e) => {
                                                e.target.style.backgroundImage = `url(${video.thumbnail})`; // ✅ Show poster while video is loading
                                            }}
                                            style={{
                                                backgroundImage: `url(${video.thumbnail})`, // ✅ Force poster for iOS
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                            }}
                                        />
                                    )}

                                    {isLoading && <div className="skeleton-loader"></div>}

                                    {/* Show Image Placeholder Only for Mobile When isActive is False */}
                                    {/* {window.innerWidth <= 1024 && !isActive && (
                                        <img
                                            className="carousel-image"
                                            src={video.thumbnail}
                                            alt={video.title}
                                            onClick={() => handleFullscreen(index)}
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                cursor: "pointer",
                                            }}
                                        />
                                    )} */}


                                    {/* ✅ Show Play Icon Only When Video is Paused */}
                                    {!isPlaying && activeVideoIndex === index && (
                                        <div className="play-overlay">
                                            <FontAwesomeIcon icon={faPlay} className="play-icon" />
                                        </div>
                                    )}

                                    <div className="v-t-c">

                                        <FontAwesomeIcon
                                            icon={faEllipsisH}
                                            className="icon menu-icon"
                                            title="More Options"
                                            onClick={toggleOptions}
                                        />
                                        {showOptions && isActive && (
                                            <div className="popup-menu">
                                                <div className="popup-inner-menu">
                                                    {/* Share Button */}
                                                    <button
                                                        className="popup-button b1"
                                                        onClick={async () => {
                                                            try {
                                                                // Copy the link to clipboard
                                                                await navigator.clipboard.writeText(video.productsUrls[0]);
                                                                toggleOptions();

                                                            } catch (error) {
                                                                //console.error("Failed to copy link:", error);
                                                            }
                                                        }}
                                                    >
                                                        Copy link
                                                    </button>


                                                    {/* Cancel Button */}
                                                    <button className="popup-button b2" onClick={toggleOptions}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>


                                        )}

                                        <p className="video-title">{video.title}</p>
                                        <div
                                            className="title-icons">
                                            <FontAwesomeIcon
                                                icon={isMuted ? faVolumeMute : faVolumeUp} // Shared mute state
                                                className={`icon speaker-icon ${isMuted ? "muted" : "unmuted"}`}
                                                title={isMuted ? "Unmute All Videos" : "Mute All Videos"}
                                                onClick={toggleMuteUnmute} // Toggle mute state for all videos
                                            />
                                            <FontAwesomeIcon
                                                icon={isFullScreen ? faCompress : faExpand}  // Change icon based on fullscreen state
                                                className="icon fullscreen-icon"
                                                // title={isFullScreen ? "Exit Fullscreen" : "Fullscreen"}

                                                //  onClick={() => handleFullscreen(index)} // ✅ Pass the clicked video index
                                                onClick={goTov}
                                            />
                                        </div>
                                    </div>

                                    {/* Updated Product Carousel Section */}
                                    {products[video.videoID] && products[video.videoID].length > 0 && (

                                        <div className="product-card-carousel-fs">

                                            <Slider {...innerSettings} 
                                                dots={false}
                                                infinite={false}
                                                speed={500}
                                                slidesToShow={1} // Show one product at a time
                                                slidesToScroll={1}
                                                
                                            >
                                                {products[video.videoID].map(
                                                    (product, productIndex) => {
                                                        //  console.log('Video URL : ',video.productsUrls[productIndex])
                                                        return (
                                                            <div
                                                                key={productIndex}
                                                                className="saro-product-card"
                                                            >
                                                                {selectedProduct?.id !== product.id && (
                                                                    <div className="product-card-content">
                                                                        {/* Header section with image, title, and price */}
                                                                        <div className="product-header"
                                                                            onClick={() => handleShopNowClick(product, video.videoID)}
                                                                        >
                                                                            <img className="product-image" src={product.image} alt={product.title} />

                                                                            <div className="product-info">
                                                                                <span className="product-title">{product.title}</span>

                                                                                <span className="product-price">
                                                                                    {product.variants?.[0]?.price?.amount || "N/A"} {product.variants?.[0]?.price?.currencyCode || ""}
                                                                                </span>


                                                                            </div>
                                                                        </div>

                                                                        {/* Shop Now Button */}
                                                                        <button className="shop-now-modal-button" onClick={() => handleShopNowClick(product, video.videoID)}>
                                                                            Shop Now &gt;
                                                                        </button>

                                                                    </div>



                                                                )}
                                                                {selectedProduct?.id === product.id && (
                                                                    <div className="product-modal">
                                                                        <div className="blur-overlay"></div>
                                                                        <div className="modal-content">
                                                                            <button className="close-button" onClick={closeModal}>
                                                                                {/* &times; */}
                                                                                <FontAwesomeIcon icon={faXmark} className="x-icon" /> {/* X Icon */}
                                                                            </button>
                                                                            <div className="scrollable-content">
                                                                                <img
                                                                                    className="modal-product-image"
                                                                                    src={selectedProduct.image}
                                                                                    alt={selectedProduct.title}
                                                                                />
                                                                                <h2 className="modal-product-title">{selectedProduct.title}</h2>


                                                                                {selectedProduct.variants.length > 0 ? (
                                                                                    <div className="variant-selector">
                                                                                        {selectedProduct.variants.length > 0 && <h3>Select Variant:</h3>}

                                                                                        {[...new Set(selectedProduct.variants.flatMap((v) => v.options.map((o) => o.name)))]
                                                                                            .map((variantType) => (
                                                                                                <div className="variant-dropdown" key={variantType}>
                                                                                                    <label>{variantType}:</label>
                                                                                                    <select
                                                                                                        value={selectedProduct.selectedOptions?.[variantType] || ""}
                                                                                                        onChange={(e) => {
                                                                                                            const newOptionValue = e.target.value;

                                                                                                            let updatedOptions = { ...selectedProduct.selectedOptions, [variantType]: newOptionValue };

                                                                                                            Object.keys(updatedOptions).forEach((key) => {
                                                                                                                if (key !== variantType) {
                                                                                                                    const isValid = selectedProduct.variants.some((variant) =>
                                                                                                                        variant.options.some((opt) => opt.name === key && opt.value === updatedOptions[key])
                                                                                                                    );
                                                                                                                    if (!isValid) {
                                                                                                                        delete updatedOptions[key]; 
                                                                                                                    }
                                                                                                                }
                                                                                                            });

                                                                                                            const matchedVariant = selectedProduct.variants.find((variant) =>
                                                                                                                variant.options.every((opt) => updatedOptions[opt.name] === opt.value)
                                                                                                            );

                                                                                                            setSelectedProduct({
                                                                                                                ...selectedProduct,
                                                                                                                selectedOptions: updatedOptions,
                                                                                                                selectedVariant: matchedVariant?.id || null,
                                                                                                            });
                                                                                                        }}
                                                                                                    >
                                                                                                        <option value="">Select {variantType}</option>
                                                                                                        {[...new Set(selectedProduct.variants.map((v) => v.options.find((o) => o.name === variantType)?.value))].map(
                                                                                                            (optionValue) => {
                                                                                                                const isAvailable = selectedProduct.variants.some((v) =>
                                                                                                                    v.options.some((o) => o.name === variantType && o.value === optionValue) &&
                                                                                                                    Object.entries(selectedProduct.selectedOptions || {}).every(([key, value]) =>
                                                                                                                        key === variantType || v.options.some((o) => o.name === key && o.value === value)
                                                                                                                    )
                                                                                                                );

                                                                                                                return (
                                                                                                                    <option key={optionValue} value={optionValue} disabled={!isAvailable}>
                                                                                                                        {optionValue} {isAvailable ? "" : "(Unavailable)"}
                                                                                                                    </option>
                                                                                                                );
                                                                                                            }
                                                                                                        )}
                                                                                                    </select>
                                                                                                </div>
                                                                                            ))}
                                                                                    </div>
                                                                                ) : (
                                                                                    <p>No variants available</p>
                                                                                )}



                                                                                <p className="modal-product-price">
                                                                                    {(() => {
                                                                                        // Find the selected variant
                                                                                        const selectedVariant = selectedProduct.variants.find(
                                                                                            (variant) => variant.id === selectedProduct.selectedVariant
                                                                                        );

                                                                                        // Extract price amount safely
                                                                                        const priceAmount = selectedVariant?.price?.amount || selectedProduct?.price?.amount || "N/A";
                                                                                        const currencyCode = selectedVariant?.price?.currencyCode || selectedProduct?.price?.currencyCode || "";

                                                                                        return `${priceAmount} ${currencyCode}`;
                                                                                    })()}
                                                                                </p>


                                                                                <h2 className="modal-product-description-heading">Description</h2>
                                                                                <p className="modal-product-description">
                                                                                    {selectedProduct.description || "No description available."}
                                                                                </p>
                                                                            </div>

                                                                            {cart.some((cartItem) => cartItem.id === product.id) ? (
                                                                                <div className="cart-actions">
                                                                                    <button
                                                                                        className="checkout-btn"
                                                                                        onClick={() => handleCheckout(selectedProduct.videoId)}
                                                                                    >
                                                                                        Checkout
                                                                                    </button>


                                                                                    {/* <button
                                                                                        className="quantity-btn"
                                                                                        onClick={() => handleRemoveFromCart(product)}
                                                                                        disabled={loadingProduct === product.id}
                                                                                    >
                                                                                        {loadingProduct === product.id ? (
                                                                                            <span className="button-loader"></span> // ✅ Button-only loader
                                                                                        ) : (
                                                                                            <FontAwesomeIcon icon={faMinusCircle} />
                                                                                        )}
                                                                                    </button> */}

                                                                                    <div className="cart-icon-wrapper">
                                                                                        {loadingProduct === product.id ? (
                                                                                            <div className="cart-loader"></div> // Black loader while processing
                                                                                        ) : (
                                                                                            <>
                                                                                                <FontAwesomeIcon
                                                                                                    icon={faShoppingCart}
                                                                                                    className="cart-icon"
                                                                                                    title="Cart"
                                                                                                    onClick={() => handleAddToCart(product)} // Handles adding to the cart
                                                                                                />
                                                                                                {(() => {
                                                                                                    const cartItem = cart.find((item) => item.id === product.id);
                                                                                                    const quantity = cartItem ? cartItem.quantity : 0;

                                                                                                    // Hide quantity badge when loader is active
                                                                                                    if (quantity > 0) {
                                                                                                        return <span className="cart-quantity-badge"></span>;
                                                                                                    }
                                                                                                    return null;
                                                                                                })()}
                                                                                            </>
                                                                                        )}
                                                                                    </div>



                                                                                </div>

                                                                            ) : (
                                                                                <button
                                                                                    className="shop-now-modal-button"
                                                                                    onClick={() => {
                                                                                        if (isShopifyConnected) {
                                                                                            handleAddToCart(selectedProduct);
                                                                                        } else if (isWooCommerceConnected) {
                                                                                            window.location.href = `${video.productsUrls[productIndex]}`;
                                                                                        }
                                                                                    }}
                                                                                    disabled={loadingProduct === selectedProduct.id}
                                                                                >
                                                                                    {loadingProduct === selectedProduct.id ? (
                                                                                        <span className="button-loader"></span>
                                                                                    ) : (
                                                                                        isShopifyConnected ? "Add to cart" : "Shop Now"
                                                                                    )}
                                                                                </button>


                                                                            )}


                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </Slider>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );


};

export default CarouselFullScreen;
